html {
  font-size: 10px;
}

body {
  font-size: 1.45em;
}

.list-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 3rem;
}

ion-app {
  max-width: 1400px;
  margin: auto;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: initial;
}

.MuiPopover-paper a {
  color: var(--ion-text-color);
}

.MuiTableCell-root div.mod {
  opacity: 0.75;
  font-size: 0.8em;
}
